import {
  ACCESS_TOKEN_NAME,
  MAX_AGE,
  REFRESH_TOKEN_NAME,
  TOKEN_ID,
} from "@/constants/auth";
import { NextPageContext } from "next";
import nookies from "nookies";

export const setItem = (
  itemName: string,
  value: string,
  ctx?: NextPageContext,
  maxAge = MAX_AGE
) => nookies.set(ctx, itemName, value, { maxAge, path: "/" });

export const getItem = (itemName: string, ctx?: NextPageContext) =>
  nookies.get(ctx, { path: "/" })[itemName];

export const removeItem = (itemName: string, ctx?: NextPageContext) =>
  nookies.destroy(ctx, itemName, { path: "/" });

export const removeToken = (ctx?: NextPageContext) => {
  nookies.destroy(ctx, ACCESS_TOKEN_NAME, { path: "/" });
  nookies.destroy(ctx, REFRESH_TOKEN_NAME, { path: "/" });
  nookies.destroy(ctx, TOKEN_ID, { path: "/" });
};
